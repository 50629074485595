<template>
    <div>
        <v-row>
            <v-col cols="12">
                <div class="d-flex justify-space-between">
                    <div style="padding: 18px 12px">
                        <div class="text-2xl"><i :class="$route.params.icon"></i> {{ $route.params.title }}</div>
                    </div>
                    <v-breadcrumbs
                        class="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        :items="$route.meta.breadcrumb"
                        divider=">"
                        large
                    />
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-stepper non-linear v-model="stepwizard">
                    <v-stepper-header>
                        <v-stepper-step step="1" >
                            ຂໍ້ມູນພັດສະດຸ
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2" >
                            ຂໍ້ມູນການຊຳລະເງີນ
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <div class="border rounded mt-2 p-1 mb-2">
                                <v-row>
                                    <v-col cols="12" >
                                        <div>
                                            <v-alert
                                                color="blue-grey lighten-4"
                                                shaped
                                                dense
                                                class="text-center"
                                            >
                                                <strong>ລາຍການຈັດສົ່ງພັດສະດຸ</strong>
                                            </v-alert>
                                        </div>
                                        <div class="d-flex justify-center">
                                            <v-radio-group
                                                v-model="searchType"
                                                row
                                                @change="changeType()"
                                            >
                                                <v-radio
                                                    label="ເລກທີຕິດຕາມພັດສະດຸ"
                                                    value="1"
                                                    color="primary"
                                                />
                                                <v-radio
                                                    label="ເບີໂທລະສັບ"
                                                    value="2"
                                                    color="primary"
                                                />
                                            </v-radio-group>
                                        </div>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-form ref="formSelectPackageData" class="pt-2" lazy-validation @submit.prevent="getSelectPakage()">
                                            <v-text-field
                                                outlined
                                                :label="(searchType == '1') ? 'ປ້ອນເລກທີຕິດຕາມພັດສະດຸ' : 'ປ້ອນເບີໂທລະສັບ'"
                                                v-model="formPackageData.package_name"
                                                :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']"
                                                append-icon="mdi-barcode-scan"
                                                dense
                                                rounded
                                                @click:append="getSelectPakage()"
                                                ref="package_name"
                                            />
                                        </v-form>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-data-table
                                            :headers="packageField"
                                            :items="listPackage"
                                            :search="search"
                                            :items-per-page="5"
                                            class="elevation-1"
                                            :loading="$store.state.auth.loading2"
                                            loading-text="ກຳລັງໂຫລດ...ກະລຸນາລໍຖ້າ"
                                            item-key="br_id"
                                            v-model="selected"
                                            fixed-header
                                            height="236px"
                                            dense
                                            :footer-props="{
                                                'items-per-page-options': [5, 10, 20, 100, -1],
                                                showFirstLastPage: true,
                                                'items-per-page-text': 'ຈຳນວນທີ່ສະແດງ:'
                                        }"
                                        >
                                        <template v-slot:item.option="{ item }">
                                            <div class="whitespace-nowrap">
                                                <v-btn
                                                    class="mr-1"
                                                    color="error"
                                                    fab
                                                    small
                                                    dark
                                                    text 
                                                    @click="deleteData(item)"
                                                >
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </div>
                                        </template>
                                        <template v-slot:item.pack_amount="{ item }">
                                            {{ $helper.formatNumber(item.pack_amount, 0) }}
                                        </template>
                                        <template v-slot:item.pack_price="{ item }">
                                            <div class="whitespace-nowrap">
                                            {{ $helper.formatNumber(item.pack_price, 0) }}
                                            </div>
                                        </template>
                                        
                                        </v-data-table>

                                    </v-col>
                                </v-row>
                            </div>
                            <div class="text-right d-flex justify-between">
                                <v-btn class="mx-1" outlined color="primary" @click="goNextStep()">ຕໍ່ໄປ <v-icon>mdi-chevron-right</v-icon></v-btn>
                                <v-btn class="mx-1" color="error" @click="$router.push({ name: 'receive'})"><v-icon>mdi-close-circle-outline</v-icon> ກັບຄືນ</v-btn>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <v-row>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <div class="mt-3">
                                        <v-alert
                                            color="blue-grey lighten-4"
                                            shaped
                                            dense
                                            class="text-center"
                                        >
                                            <strong>ລວມມູນຄ່າທີ່ຕ້ອງຊຳລະ</strong>
                                        </v-alert>
                                    </div>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-alert
                                                color="yellow red--text"
                                                dense
                                                class="text-center whitespace-nowrap d-none d-sm-block d-md-block d-lg-block d-xl-block"
                                            >
                                                <i class="text-2xl">{{ $helper.formatNumber(sumtotal,0) }} {{ sumccy }}</i>
                                            </v-alert>
                                            <v-alert
                                                color="yellow red--text"
                                                dense
                                                class="text-center whitespace-nowrap d-block d-sm-none d-md-none d-lg-none d-xl-none"
                                            >
                                                <i>{{ $helper.formatNumber(sumtotal,0) }} ກີບ</i>
                                            </v-alert>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="5" lg="4" xl="4">
                                            <div class="border rounded mt-4 p-2">
                                                <div>
                                                    <v-alert
                                                        color="red lighten-4"
                                                        shaped
                                                        dense
                                                        class="text-center"
                                                    >
                                                        <strong>ຂໍ້ມູນຜູ້ຮັບພັດສະດຸ</strong>
                                                    </v-alert>
                                                </div>
                                                
                                            <v-form ref="formPackageDataref" class="pt-2" lazy-validation >
                                                <div>
                                                    <v-autocomplete
                                                        label="ຂໍ້ມູນຜູ້ຮັບພັດສະດຸ"
                                                        :items="listCustomer"
                                                        item-text="text"
                                                        item-value="id"
                                                        outlined  
                                                        dense
                                                        clearable
                                                        v-model="formCustomerData.customer" 
                                                        @change="getListSelectCustomerData()"
                                                    />
                                                </div>
                                                <div>
                                                    <v-text-field 
                                                        type="text" 
                                                        label="ຊື່ຜູ້ຮັບພັດສະດຸ" 
                                                        outlined  
                                                        dense
                                                        v-model="formCustomerData.customer_name" 
                                                        :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']" 
                                                        required 
                                                        append-icon="mdi-card-account-mail-outline"  
                                                    />
                                                </div>
                                                <div>
                                                    <v-text-field 
                                                        type="text" 
                                                        label="ເບີໂທລະສັບຜູ້ຮັບພັດສະດຸ" 
                                                        outlined  
                                                        dense
                                                        v-model="formCustomerData.customer_tel" 
                                                        :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']" 
                                                        required 
                                                        append-icon="mdi-card-account-phone"  
                                                    />
                                                </div>
                                                <div>
                                                    <v-textarea
                                                        label="ລາຍລະອຽດຜູ້ຮັບພັດສະດຸ" 
                                                        outlined  
                                                        v-model="formCustomerData.customer_address" 
                                                        :rows="3"
                                                    />
                                                </div>
                                            </v-form>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="7" lg="8" xl="8">
                                            <div class="border rounded mt-4 p-2">
                                                <div>
                                                    <v-alert
                                                        color="green lighten-4"
                                                        shaped
                                                        dense
                                                        class="text-center"
                                                    >
                                                        <strong>ຂໍ້ມູນການຊຳລະເງີນ</strong>
                                                    </v-alert>
                                                </div>
                                                <div>
                                                    <v-select
                                                        label="ປະເພດການຊຳລະ"
                                                        :items="listPayType"
                                                        item-text="text"
                                                        item-value="id"
                                                        outlined  
                                                        dense
                                                        v-model="formCustomerData.paytype" 
                                                        :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']"
                                                    />
                                                </div>
                                                <div>
                                                    <v-textarea
                                                        label="ລາຍລະອຽດການຊຳລະ" 
                                                        outlined  
                                                        v-model="formCustomerData.paydesc" 
                                                        :rows="8"
                                                    />
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <div class="text-right d-flex justify-between">
                                <v-btn class="mx-1" outlined color="primary" @click="stepwizard = 1"><v-icon>mdi-chevron-left</v-icon> ກັບຄືນ</v-btn>
                                <div class="d-flex">
                                    <v-btn class="mx-1" color="success" @click="saveData()"><v-icon>mdi-content-save-outline</v-icon>ບັນທຶກ</v-btn>
                                    <v-btn class="mx-1" color="error" @click="$router.push({ name: 'receive'})"><v-icon>mdi-close-circle-outline</v-icon> ກັບຄືນ</v-btn>
                                </div>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
    </div>
</template>


<script>
    export default {
        watch: {
            stepwizard(data){
                if(data == 1){
                    this.isprocess = 50;
                }else if(data == 2){
                    this.isprocess = 100;
                }
            }
        },
        data() {
            return {
                stepwizard: 1,
                req_id: '',
                action: '',
                customerValid: false,
                packageValid: false,
                formManage: {
                    br_name_la: '',
                    br_name_en: '',
                    br_tel: '',
                    br_address: '',
                },
                packageField: [
                    {
                        text: 'ຕົວເລືອກ',
                        align: 'center',
                        sortable: false,
                        value: 'option',
                        class: 'black--text title amber lighten-1 black--text whitespace-nowrap'
                    },
                    {
                        text: '#',
                        align: 'center',
                        sortable: false,
                        value: 'rno',
                        class: 'black--text title amber lighten-1 black--text whitespace-nowrap'
                    },
                    {
                        text: 'ເລກທີຕິດຕາມພັດສະດຸ',
                        align: 'center',
                        sortable: false,
                        value: 'track_code',
                        class: 'black--text title amber lighten-1 black--text whitespace-nowrap'
                    },
                    {
                        text: 'ຊື່ພັດສະດຸ',
                        align: 'start',
                        sortable: false,
                        value: 'pack_name',
                        class: 'black--text title amber lighten-1 black--text whitespace-nowrap'
                    },
                    {
                        text: 'ຈຳນວນ',
                        align: 'center',
                        sortable: false,
                        value: 'pack_amount',
                        class: 'black--text title amber lighten-1 black--text whitespace-nowrap'
                    },
                    {
                        text: 'ລາຄາ',
                        align: 'end',
                        sortable: false,
                        value: 'pack_price',
                        class: 'black--text title amber lighten-1 black--text whitespace-nowrap'
                    },
                    {
                        text: 'ລາຍລະອຽດ',
                        align: 'start',
                        sortable: false,
                        value: 'pack_remark',
                        class: 'black--text title amber lighten-1 black--text col-2 whitespace-nowrap'
                    },
                ],
                listBranch: [],
                listCustomer: [],
                listPayType: [
                    { id: 'C', text: 'ເງີນສົດ' },
                    { id: 'T', text: 'ເງີນໂອນ' },
                    { id: 'D', text: 'ຕິດໜີ້' },
                ],
                selected: [],
                search: "",
                listdata: [],
                formCustomerData: {
                    customer: '',
                    customer_name: '',
                    customer_tel: '',
                    customer_address: '',
                    paytype: 'C',
                    paydesc: '',
                },
                formPackageData: {
                    package_name: '',
                },
                listPackage: [],
                sumtotal: 0,
                sumccy: '',
                searchType: "1",
            }
        },
        async created(){
            await this.getListSelectboxCustomer();
        },
        methods: {
            async getListSelectboxCustomer(){
                const formdata = await {
                    action: "listcustomer",
                    id: "",
                }
                this.listCustomer = await [];
                const result = await this.$store.dispatch("params/getListSelectBox",formdata);
                if(result.success){
                    this.listCustomer = await result.data;
                }
            },
            async changeType(){
                this.formPackageData.package_name = await "";
                this.listPackage = await [];
                await this.$refs.package_name.focus();
            },  
            async getSelectPakage(){
                if(this.formPackageData.package_name == ""){
                    this.$refs.formSelectPackageData.validate();
                    return false;
                }else{
                    if(this.searchType == "1"){
                        var checkdupplicatetrackcode = 0;
                        for (const [i, rows] of Object.entries(this.listPackage)) {
                            if(rows.track_code == this.formPackageData.package_name){
                                checkdupplicatetrackcode++;
                            }
                        }
                        if(checkdupplicatetrackcode == 0){
                            const formdata = await {
                                action: "selectpackageforreceive",
                                type: this.searchType,
                                code: this.formPackageData.package_name,
                            }
                            const result = await this.$store.dispatch("params/getListSelectBox",formdata);
                            if(result.success){
                                var obj = await {
                                    rno: 0,
                                    track_code: result.data.track_code,
                                    pack_name: result.data.pack_name,
                                    pack_amount: result.data.pack_amount,
                                    pack_price: result.data.pack_price,
                                    pack_ccy: result.data.pack_ccy,
                                    pack_remark: result.data.pack_remark,
                                }
                                await this.listPackage.push(obj);
                                for (const [i, rows] of Object.entries(this.listPackage)) {
                                    rows.rno = await Number.parseInt(i) + 1;
                                }
                                this.formPackageData.package_name = await "";
                                await this.$refs.package_name.focus();
                                await this.calSumtotal();
                            }else{
                                this.$swal.fire("Oops", result.message, "warning");
                            }
                        }else{
                            this.$swal.fire("Oops", "ພັດສະດຸເລກທີ " + this.formPackageData.package_name + " ມີໃນຕາຕະລາງແລ້ວ", "warning");
                            this.formPackageData.package_name = await "";
                            await this.$refs.package_name.focus();
                        }
                    }else{
                        const formdata = await {
                            action: "selectpackageforreceive",
                            type: this.searchType,
                            code: this.formPackageData.package_name,
                        }
                        const result = await this.$store.dispatch("params/getListSelectBox",formdata);
                        if(result.success){
                            var checkdupplicatetrackcode = 0;
                            for (const [i, rows] of Object.entries(this.listPackage)) {
                                for (const [key, value] of Object.entries(result.data)) {
                                    if(rows.track_code == value.track_code){
                                        checkdupplicatetrackcode++;
                                    }
                                }
                            }
                            if(checkdupplicatetrackcode == 0){
                                for (const [i, rows] of Object.entries(result.data)) {
                                    var obj = await {
                                        rno: 0,
                                        track_code: rows.track_code,
                                        pack_name: rows.pack_name,
                                        pack_amount: rows.pack_amount,
                                        pack_price: rows.pack_price,
                                        pack_ccy: rows.pack_ccy,
                                        pack_remark: rows.pack_remark,
                                    }
                                    await this.listPackage.push(obj);
                                }
                                for (const [i, rows] of Object.entries(this.listPackage)) {
                                    rows.rno = await Number.parseInt(i) + 1;
                                }

                                this.formPackageData.package_name = await "";
                                await this.$refs.package_name.focus();
                                await this.calSumtotal();
                            }else{
                                this.$swal.fire("Oops", "ມີເລກທີພັດສະດຸໃນຕາຕະລາງແລ້ວ...ກະລຸນາເຄຍລາຍການກ່ອນ", "warning");
                                this.formPackageData.package_name = await "";
                                await this.$refs.package_name.focus();
                            }
                        }else{
                            this.$swal.fire("Oops", result.message, "warning");
                        }
                    }
                }
            },
            async getListSelectCustomerData(){
                const formdata = await {
                    action: "selectcustomerbyid",
                    id: this.formCustomerData.customer,
                }
                this.formCustomerData.customer_name = await '';
                this.formCustomerData.customer_tel = await '';
                this.formCustomerData.customer_address = await '';
                const result = await this.$store.dispatch("params/getListSelectBox",formdata);
                if(result.success){
                    this.formCustomerData.customer_name = await result.data.cus_name;
                    this.formCustomerData.customer_tel = await result.data.cus_tel;
                    this.formCustomerData.customer_address = await result.data.cus_address;
                }
            },
            async goNextStep(){
                if(this.listPackage.length == 0){
                    this.$swal.fire("Oops", "ກະລຸນາປ້ອນຂໍ້ມູນພັດສະດຸກ່ອນ", "warning");
                }else{
                    await this.calSumtotal();
                    this.stepwizard = await 2;
                }
            },
            async deleteData(data){
                this.$swal.fire({
                    title: 'ລຶບຂໍ້ມູນ',
                    text: 'ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ຫລື ບໍ່ ?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#85ce61',
                    cancelButtonColor: '#F56C6C',
                    confirmButtonText: '<i class="mdi mdi-check-circle-outline"></i>  ຕົກລົງ',
                    cancelButtonText: '<i class="mdi mdi-close-circle-outline"></i>  ຍົກເລີກ'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        var isindex = 0;
                        for (const [i, rows] of Object.entries(this.listPackage)) {
                            if(rows.rno == data.rno){
                                isindex = i;
                            }
                        }
                        await this.listPackage.splice(isindex, 1);
                        for (const [i, rows] of Object.entries(this.listPackage)) {
                            rows.rno = await Number.parseInt(i) + 1;
                        }
                        this.formPackageData.package_name = await "";
                        await this.$refs.package_name.focus();
                        await this.calSumtotal();
                    }
                });
            },
            async saveEdit(){
                this.$swal.fire({
                    title: 'ແກ້ໄຂຂໍ້ມູນ',
                    text: 'ທ່ານຕ້ອງການບັນທຶກແກ້ໄຂຂໍ້ມູນ ຫລື ບໍ່ ?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#85ce61',
                    cancelButtonColor: '#F56C6C',
                    confirmButtonText: '<i class="mdi mdi-check-circle-outline"></i>  ຕົກລົງ',
                    cancelButtonText: '<i class="mdi mdi-close-circle-outline"></i>  ຍົກເລີກ'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.listPackage[this.packageindex].package_name = await this.formEditPackageData.package_name;
                        this.listPackage[this.packageindex].package_amount = await this.formEditPackageData.package_amount;
                        this.listPackage[this.packageindex].package_price = await this.formEditPackageData.package_price;
                        this.listPackage[this.packageindex].package_description = await this.formEditPackageData.package_description;
                        this.formDialog = await false;
                        var total = await 0;
                        for (const [i, rows] of Object.entries(this.listPackage)) {
                            rows.rno = await Number.parseInt(i) + 1;
                            total += await Number.parseFloat(rows.package_price);
                        }
                        this.sumtotal = await total;
                        this.$toast.fire("ແກ້ໄຂສຳເລັດ","","success");
                    }
                });
            },
            async saveData(){
                if(this.formCustomerData.customer_name == '' || this.formCustomerData.customer_tel == ''){
                    this.$refs.formPackageDataref.validate();
                    return false;
                }else{
                    this.$swal.fire({
                        title: 'ບັນທຶກຂໍ້ມູນ',
                        text: 'ທ່ານຕ້ອງການບັນທຶກຂໍ້ມູນ ຫລື ບໍ່ ?',
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#85ce61',
                        cancelButtonColor: '#F56C6C',
                        confirmButtonText: '<i class="mdi mdi-check-circle-outline"></i>  ຕົກລົງ',
                        cancelButtonText: '<i class="mdi mdi-close-circle-outline"></i>  ຍົກເລີກ'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            const formdata = await {
                                action: 'addreceive',
                                customer_name : this.formCustomerData.customer_name,
                                customer_tel : this.formCustomerData.customer_tel,
                                customer_address : this.formCustomerData.customer_address,
                                total: this.sumtotal,
                                paytype: this.formCustomerData.paytype,
                                remark: this.formCustomerData.paydesc,
                                listpackage: this.listPackage,
                            }
                            await this.$store.commit("auth/setLoading", true);
                            const result = await this.$store.dispatch("manage/getManagePackage", formdata);
                            await this.$store.commit("auth/setLoading", false);
                            if(result.success){
                                this.$toast.fire(result.message,"","success");
                                await this.$router.push({name: 'receive'});
                                
                            }else{
                                this.$swal.fire("Oops",result.message,"warning");
                            }
                        }
                    });
                }
                
            },
            async calSumtotal(){
                var sumtotal = 0;
                var sumlak = '';
                for (const [i, rows] of Object.entries(this.listPackage)) {
                    sumtotal += Number.parseFloat(rows.pack_price);
                    sumlak = rows.pack_ccy;
                }
                this.sumtotal = await sumtotal;
                this.sumccy = await sumlak;
            }
        },
    }
</script>


<style scoped>
    .v-stepper .v-stepper--non-linear .v-sheet .theme--light{
        background: transparent !important;
    }
    .v-sheet.v-stepper:not(.v-sheet--outlined){
        box-shadow: none !important;
    }

    .v-stepper__header{
        height: 25px !important;
        box-shadow: none !important;
    }
    .v-stepper__step{
        padding: 10px 25px !important;
    }

    .row{
        margin: 0 !important;
    }

    .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1{
        padding: 5px !important;
    }

    .v-input--selection-controls{
        margin: 0 !important;
        padding: 0 !important;
    }
</style>